import 'react-toastify/dist/ReactToastify.css';

import {
  HashRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { UserProvider } from '../context_provider/user_provider';
import Login from '../screen/login';
import LoginProcess from '../screen/login_process';
import SelectTest from '../screen/select_test';
import routingPath from './router_path';

var routing = (
    <HashRouter>
        <UserProvider>
            <Routes>
                <Route path="*" Component={Login} />
                <Route path={routingPath.login} Component={Login}/>
                <Route path={routingPath.loginProcss} Component={LoginProcess} />
                <Route path={routingPath.selectTest} Component={SelectTest} />
            </Routes>
            <ToastContainer limit={4} position="bottom-right"/>
        </UserProvider>
    </HashRouter>
);

export default routing;