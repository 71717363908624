const orgs:any = {
    logo:{
        key: "logo",
        title:"NxBas",
        icon:"/logo/nxbas.avif"
    },
    logo1:{
        key: "logo1",
        title:"Comapny logo 1",
        icon:"/logo/logo1.png"
    },
    logo2:{
        key: "logo2",
        title:"Comapny logo 2",
        icon:"/logo/logo2.png"
    },
    logo3:{
        key: "logo3",
        title:"Comapny logo 3",
        icon:"/logo/logo3.png"
    },
    logo4:{
        key: "logo4",
        title:"Comapny logo 4",
        icon:"/logo/logo4.png"
    }
}
export default orgs;