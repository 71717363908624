import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import arabic from './langs/ar.json';
import english from './langs/en.json';
import malay from './langs/ms.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {translation:english},
    ms: {translation:malay},
    ar:{translation:arabic}
  },
  fallbackLng:'en',
  debug: true,
});

export default i18n;