import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { UserContext } from '../context_provider/user_provider';
import endpoints from '../data/network/API_ENDPOINT';
import HTTP from '../data/network/HTTP';
import routingPath from '../routing/router_path';

type TestGroup = {
  blocked_by_group: string;
  position: string;
  title: string;
  tests: { test_code: string; language: string }[];
  testTaker: {
    id: number | null;
    userId: number;
    schedule_id: number;
    batteryId: string;
    groupId: string;
    testId: string | null;
    language: string | null;
    dpaAccepted: boolean | null;
    status: string;
    demographicSectionCompleted: boolean | null;
    lastVisitedDemographicQuestionPosition: number;
    lastVisitedQuestionPosition: number;
    lastVisitedSection: number;
    onBoardingSectionsCompleted: boolean | null;
    userPhotoUploaded: boolean;
  };
  _blocked_by: boolean;
};

type TestData = {
  testGroups: TestGroup[];
};

const toDateAndTime=(timestamp:number)=>{
  var date = new Date(timestamp);
  return `${date.getDate().toString().padStart(2,'0')}/${(date.getMonth()+1).toString().padStart(2,'0')}/${date.getFullYear()} 
  ${date.getHours().toString().padStart(2,'0')}:${date.getMinutes().toString().padStart(2,'0')}`;
}

function SelectTest() {
  const location = useLocation();
  const navigate = useNavigate();
  const ctx: any = useContext(UserContext);
  const [testTaker, setTestTaker] = useState([]); 
  const [testGroup, setTestGroup] = useState([]);
  const { t, i18n } = useTranslation();

  const btn: any = {
    not_started:
      "not_started text-white font-medium rounded-md text-xs px-4 py-3",
    started:
      "min-w-28 bg-[#007bff] hover:bg-blue-500 text-white font-medium rounded-md text-xs px-2 py-1.5",
    answering:
      "min-w-28 bg-green-500  text-white font-medium rounded-md text-xs px-2 py-1.5",
    finished:
      "min-w-28 bg-gray-400 hover:bg-purple-400 text-white font-medium rounded-md text-xs px-2 py-1.5",
    time_out:
      "min-w-28 text-purple-800 bg-transparent font-medium rounded-md text-xs px-2 py-1.5",
  };

  async function loadTestTakers() {
    try {
      let responseData = await HTTP.GET(endpoints.getAssessments);
      if (responseData.status === 200) {
        let newTestTakers = responseData.data.flatMap((testGroup: TestData) =>
          testGroup.testGroups.map((group) => group.testTaker)
        );
        setTestTaker(newTestTakers);
      }
    } catch (error) {
      console.error("Error fetching test takers:", error);
    }
  }

  async function loadTestGroups() {
    try {
      let responseData = await HTTP.GET(endpoints.getAssessments);
      if (responseData.status === 200) {
        let newTestGroups = responseData.data.flatMap((dataItem: any) =>
          dataItem.testGroups.map((group: any) => ({
            ...group,
            "title" : dataItem['schedule']['scheduleTitle'],
            "startTime" : dataItem['schedule']['startTime'],
            "finishTime" : dataItem['schedule']['finishTime'],
          }))
        );
        setTestGroup(newTestGroups);
      }
    } catch (error) {
      console.error("Error fetching test Groups:", error);
    }
  }

  useEffect(() => {
    if(!ctx.token && location.pathname!==routingPath.login){
      navigate(`${routingPath.login}`,{replace:true});
    }
    loadTestTakers();
    loadTestGroups();
  }, []);

  async function choose(testCode: string, language: string, test: any) {
    ctx.setBusy(true);
    debugger;
    try {
      let response = await HTTP.POST(endpoints.startResume, {
        schedule_id: `${test.testTaker["scheduleId"]}`,
        battery_id: `${test.testTaker["batteryId"]}`,
        group_id: `${test.testTaker["groupId"]}`,
        test_code: testCode,
        test_lang: language,
      });
      if(response.status===200){ 
        i18n.changeLanguage(language.toLowerCase());
        ctx.setLng(language.toLowerCase());
        // window.location.replace(`http://localhost:4000/#/?no_proctoring=yes&tt=${response.data.id}&token=${sessionStorage.getItem("token")}&callback=https://exam-pro-web.gpsytech.com/#/select_test`);
        window.location.replace(`https://www.test-taking-web.gpsytech.com/#/?tt=${response.data.id}&token=${sessionStorage.getItem("token")}&callback=https://exam-pro-web.gpsytech.com/#/select_test`);
      }
    } catch (error) {
      ctx.setBusy(false);
      toast.error(ctx.t("start_test_fail"));
    }
    ctx.setBusy(false);
  }

  return (
    <div>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <img src={ctx.org.icon} className="h-12" alt="NxBAS Logo" />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 items-center text-center">
              <p className="text-sm sm:mr-6 uppercase">
                ID : {ctx.user["username"]}
              </p>
              <button onClick={()=>{ctx.logOut()}} 
              className="focus:outline-none text-white bg-[#007BFF] hover:bg-blue-500 font-medium rounded-md text-sm px-10 py-2">{t("log_out")}</button>
            </div>
          </div>
        </div>
      </nav>
      <div className="p-4">

          <div className="items-center justify-center">
            {testTaker.length === 0? ( 
              <h4 className="text-lg mt-[40vh] text-center font-bold">
                {t("no_test_available")}
              </h4>
            ) : (
              <div className="pt-20">
                <p className="text-sm text-center mb-5">
                  {t("please_choose_assessment")}
                </p>
                <br />
                <div className="relative scrollx1">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-6 border py-5">
                              {t("assessment")}
                            </th>
                            <th scope="col" className="px-6 border py-5">
                              {t("lang_available")}
                            </th>
                            <th scope="col" className="px-6 border py-5">
                              {t("valid_till")}
                            </th>
                            <th scope="col" className="px-6 border text-center  py-1 ">
                              {t("status")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {testGroup.map((test: any, index: number) => (
                            <React.Fragment key={`R${index}`}>
                              {test.testTaker.testId != null ? (
                                <tr
                                  key={`R${index}-I1`}
                                  className="odd:bg-white border text-gray-600 border-slate-300 odd:dark:bg-gray-900 hover:bg-gray-200 even:bg-gray-50 even:dark:bg-gray-800"
                                >
                                  <td className="px-6 py-4 border font-normal whitespace-nowrap dark:text-white">
                                    {test.testTaker.testId}
                                  </td>
                                  <td className="px-6 border py-4">
                                    {test.testTaker.language
                                      ? t(test.testTaker.language.toLowerCase())
                                      : "....."}
                                  </td>
                                  <td className="px-6 border py-4">
                                    {toDateAndTime(test["finishTime"])}
                                  </td>
                                  <td className="px-6  py-4 flex justify-center">
                                    {test.testTaker["status"] !== "FINISHED" && (
                                      <button
                                        onClick={() => {
                                          choose(
                                            test.testTaker["testId"],
                                            test.testTaker["language"],
                                            test
                                          );
                                        }}
                                        className={btn[test.testTaker["status"].toLowerCase()]}>
                                        {t(`${test.testTaker["status"].toLowerCase()}`)}
                                      </button>
                                    )}
                                    {test.testTaker["status"] === "FINISHED" && (
                                      <button
                                      className="border rounded-md px-3 py-2 bg-gray-200 font-bold"
                                      onClick={() => {}}>
                                      {t("finished")}
                                      </button>
                                      // <button
                                      //   className="bg-green-500  hover:bg-white hover:text-green-500 text-white p-1 px-2 rounded-md text-[0.8rem]"
                                      //   onClick={() => {
                                      //     const link = document.createElement("a");
                                      //     link.href =
                                      //       process.env.PUBLIC_URL + "/score_demo.pdf";
                                      //     link.download = "score_demo.pdf";

                                      //     document.body.appendChild(link);
                                      //     link.click();
                                      //     document.body.removeChild(link);
                                      //   }}
                                      // >
                                      // {t("download_report")}
                                      // </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                test.tests.map((testItem: any, itemIndex: number) => (
                                  <tr
                                    key={`R${index}-I${itemIndex}`}
                                    className="odd:bg-white  odd:dark:bg-gray-900 border border-slate-300 hover:bg-gray-200 even:bg-gray-100 even:dark:bg-gray-800"
                                  >
                                    <td className="px-6 border py-4 font-normal  whitespace-nowrap dark:text-white">
                                      {testItem["test_code"]}
                                    </td>
                                    <td className="px-6 border py-4">
                                      {testItem["language"]
                                        ? t(testItem["language"].toLowerCase())
                                        : "....."}
                                    </td>
                                    <td className="px-6 border py-4">
                                      {toDateAndTime(test["finishTime"])}
                                    </td>
                                    <td className="px-6  py-6 flex justify-center">
                                      <button
                                        onClick={() => {
                                          choose(
                                            testItem["test_code"],
                                            testItem["language"],
                                            test
                                          );
                                        }}
                                        className={
                                          btn[test.testTaker["status"].toLowerCase()]
                                        }
                                      >
                                        {t(`${test.testTaker["status"].toLowerCase()}`)}
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                    </table>
                </div>
              </div>
            )}            
          </div>
      </div>
    </div>
  );
}

export default SelectTest;
