import './screen/index.css';
import './i18n/i18n';
import './data/network/interceptor';

import ReactDOM from 'react-dom';

import routing from './routing/router';
import reportWebVitals from './screen/reportWebVitals';

ReactDOM.render(routing,document.getElementById('root') as HTMLElement);
reportWebVitals();