import {
  useContext,
  useEffect,
} from 'react';

import EventEmitter from 'events';
import { initFlowbite } from 'flowbite';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import orgs from '../company-list';
import { UserContext } from '../context_provider/user_provider';
import { LoginModel } from '../model/login_model';
import routingPath from '../routing/router_path';

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t,i18n } = useTranslation();
  const ctx:any = useContext(UserContext);
  let events = new EventEmitter();

  function submitForm(event:any):void {
    event.preventDefault();
    var payload = new LoginModel(); 
    for (let i = 0; i < event.target.length; i++) {
      if(event.target[i].localName==='input') {
        if(event.target[i].name==="username")
          payload.username = event.target[i].value;
        else
          payload.password = event.target[i].value;
      }
    }
    if(payload.isValid())
      navigate(routingPath.loginProcss,{state:payload});
    else
      toast.info("invalid value");
  }

  const changeLng = (lng:string)=>{
    i18n.changeLanguage(lng);
    document.documentElement.dir = lng?.toLowerCase()==="ar"? "rtl":"ltr";  
    ctx.setLng(lng);
  }

  useEffect(()=>{
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
    });
    if(location.pathname.replace("/","").length>2 && location.pathname.replace("/","").indexOf("login")===-1){
      ctx.setOrg(orgs[location.pathname.replace("/","")]??orgs[sessionStorage.getItem("org")??""]??orgs.logo);
      sessionStorage.setItem("org",ctx.org["key"]);
    }
    initFlowbite();
    ctx.checkLogin();
    events.addListener("new",()=>{
      console.log("new event catch");
    })
    events.emit("new");
    
    let event = new EventEmitter();
    event.emit("new");
    event.addListener("new",()=>{
      console.log("new found");
    })
  })

  return (
    <div className="">
      <nav className="bg-white border-gray-200">
        <div className="max-w-full flex flex-wrap items-center justify-between mx-auto p-4">
          <img src={ctx.org.icon} className="h-14" alt="NxBAS Logo" />
          <div id="navbar-dropdown">
            <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" className="rounded-md flex items-center justify-between w-full py-2 px-3 text-gray-90 border-2 border-s">
              {t("selected_lng")} 
              <svg className="w-2.5 h-2.5 ms-8" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
              </svg>
            </button>
            <div id="dropdownNavbar" className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-32">
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                <li>
                  <button onClick={()=>{changeLng("en")}} className="w-full block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">English</button>
                </li>
                <li>
                  <button onClick={()=>{changeLng("ms")}} className="w-full block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Melayu</button>
                </li>
                <li>
                  <button onClick={()=>{changeLng("ar")}} className="w-full block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Arabic</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <hr/>
      <div className='Login absolute top-1/3 w-screen  px-5'>
          <form onSubmit={submitForm} style={{backgroundImage: "linear-gradient(45deg, rgb(255 141 141), rgb(172 172 255))"}} action='' className="text-center max-w-sm mx-auto block bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="bg-white m-1 px-10 py-14 rounded-md">
              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                  </svg>
                </span>
                <input 
                  onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t("please_enter",{value:t("username")}))}
                  onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                  type="text" required name='username' 
                  id="admin_username" 
                  // defaultValue={"USER002"}
                  className="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  placeholder={t("username")}
                />
              </div>
              <div className="flex mt-5">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path strokeLinejoin="round" strokeWidth="2" d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H7a1 1 0 0 1-1-1v-7c0-.6.4-1 1-1Z"/>
                  </svg>
                </span>
                <input 
                // defaultValue={"USER002"}
                onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t("please_enter",{value:t("password")}))}
                onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                type="password" required name='password' id="admin_password" className="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                placeholder={t("password")}/>
              </div>
              <button type="submit" className="w-10/12 mt-10 text-white bluebg hover:bg-blue-800 font-medium rounded-md py-2.5">{t("enter")}</button>
            </div>
          </form>
      </div>
    </div>
  );

}
export default Login;